<template>
	<el-dialog :visible.sync="dialogVisible" append-to-body :show-close="false" width="400px" lock-scroll>
		<template slot="title"><i class="el-icon-info" style="color:#909399;" />{{"\u3000"}}溫馨提示</template>
		<div v-show="type < 2" style="margin-top:-20px; margin-bottom:-20px;">
			<template v-show="type < 1">
				如果您是會員請先
				<el-link type="primary">
					<router-link to="/login">登入</router-link>
				</el-link>
				喔！<br>或{{"\u3000"}}加入
				<el-link type="danger">
					<router-link to="/join/donor">椅友會員</router-link>
				</el-link>
				取得優惠價格！<br>
			</template>
		</div>
		<div slot="footer" class="row" align-x="right">
			<el-button @click="dialogVisible = false" type="info" plain size="mini">下次再說</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
	data() {
		return {
			dialogVisible: false,
		}
	},
	computed: {
		...mapState("auth", ["_auth"]),
		type() {
			const { roles = new Array } = this._auth || new Object;
			return roles.includes("donor") ? 2 : roles.includes("member") ? 1 : 0;
		}
	},
	methods: {
		check() {
			return new Promise((resolve, reject) => {
				if (this.type) resolve(true);
				else {
					this.dialogVisible = true;
					resolve(false);
				}
			});
		}
	},
	mounted() {
		this.check();
	}
}
</script>